import { signOut } from 'aws-amplify/auth';

import { userSessionData } from '../user-session-data';
import { LogoutReason } from '../user-session-state-machine';

export async function clearUserSession(event: any) {
	if (event.data?.reason === LogoutReason.MULTIPLE_ACCOUNT_INSTANCES) {
		userSessionData.logoutReason.set(LogoutReason.MULTIPLE_ACCOUNT_INSTANCES);
	}

	userSessionData.currentAccount.remove();
	userSessionData.oxidAccessToken.remove();
	userSessionData.requestedUrl.remove();
	await signOut();
}
