import { DEFAULT_COOKIE_ATTRIBUTES } from '@packages/static/auth';
import * as CONFIG from '@packages/static/config';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import cookies from 'js-cookie';
import { ReactNode } from 'react';

import { CurrentUserSessionAccount } from '@/providers/UserSessionProvider/types';
import { UserAccount } from '@/schema.types';

/**
 * @deprecated use useUserSessionTokens instead where possible !
 */
export const oxidAccessTokenAtom = atom<string | undefined>(undefined);

/**
 * @deprecated use useUserSessionTokens instead where possible !
 */
export const cognitoAccessTokenAtom = atom<string | undefined>(undefined);

/**
 * @deprecated use useUserSessionAccounts instead where possible !
 */
export const currentAccountAtom = atom<CurrentUserSessionAccount | null>(null);

/** ----------------------------------------------------------------------------------------------
 * User and Account State
 * _______________________________________________________________________________________________ */

export const accountListAtom = atom<UserAccount[]>([]);

/** ----------------------------------------------------------------------------------------------
 * Atoms to track app state, errors and async actions
 * _______________________________________________________________________________________________ */

export const urlHistoryStackAtom = atom<string[]>([]);

/**
 * just collect all errors, show the count in a bubble and provide a dedicated page to display end remove all
 */
export const errorsAtom = atomWithReset<
	{ message: string; id: string; timestamp: Date; error?: Error; details?: Record<string, any> }[]
>([]);

const asyncActionsListAtom = atom<Promise<any>[]>([]);

/**
 * we put all async actions in there and remove them when they are resolved
 */
export const asyncActionsAtom = atom<Promise<any>[], Promise<any>[], Promise<any>[]>(
	(get) => {
		return get(asyncActionsListAtom);
	},
	(get, set, update) => {
		void update.finally(() => {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			get(asyncActionsListAtom).splice(get(asyncActionsListAtom).indexOf(update), 1);
		});

		set(asyncActionsListAtom, [...get(asyncActionsListAtom), update]);
		return get(asyncActionsListAtom);
	},
);

export const debugModeAtom = atom<boolean, boolean[], boolean>(
	() => {
		console.log('get debugModeAtom');
		return typeof cookies.get(CONFIG.params.cookies.appOrigin) === 'string' ? true : false;
	},
	(_, __, update) => {
		console.log('update debugModeAtom', CONFIG.params.cookies.appOrigin, update);
		if (update) {
			cookies.set(CONFIG.params.cookies.appOrigin, 'https://ecom-local.oxid.one', {
				...DEFAULT_COOKIE_ATTRIBUTES,
				expires: 365,
			});
			return true;
		} else {
			cookies.remove(CONFIG.params.cookies.appOrigin, DEFAULT_COOKIE_ATTRIBUTES);
			return false;
		}
	},
);

export const topBarItemsAtom = atom<Map<string, ReactNode>>(new Map());
