/* eslint-disable import/no-anonymous-default-export */

const config = {
	Auth: {
		Cognito: {
			userAttributes: {
				email: {
					required: true,
				},
				sub: {
					required: true,
				},
				name: {
					required: true,
				},
			},
			userPoolClientId: '1heh350ilb0ee2devm6400aa77',
			// identityPoolId: 'eu-central-1:20884072-860f-4789-b50c-236067f08998',
			signUpVerificationMethod: 'link',

			userPoolId: 'eu-central-1_wY71Qmp1d',
			loginWith: {
				oauth: {
					domain: 'auth.oxid.one',
					scopes: ['openid', 'email'],
					redirectSignIn: [
						'http://localhost',
						'https://dashing-blatantly-rattler.ngrok-free.app/auth/callback',
						'https://oauth.pstmn.io/v1/callback',
						'https://www.oxid.one/ecom/auth/callback',
					],
					redirectSignOut: [
						'http://localhost',
						'https://admin.oxid.one',
						'https://dashing-blatantly-rattler.ngrok-free.app',
					],
					responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
				},
			},
		},
		// identityPoolRegion: 'eu-central-1',

		// mandatorySignIn: true,

		// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
		// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
		// signUpVerificationMethod: 'code', // 'code' | 'link'

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		// oauth: {

		// },
	},
} satisfies import('aws-amplify').ResourcesConfig;

export default config;
