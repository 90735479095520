/**
 * we put the store here to be able to add
 * some listener logic without polluting
 * other state files
 */

import { createStore } from 'jotai';

export const globalStore = createStore();

export const userSessionStore = createStore();
