import { OxidAccessTokenPayload } from '@packages/static/auth';
import * as Sentry from '@sentry/nextjs';
import * as jose from 'jose';
import '@packages/appsync/utils/polyfill';

import { debugs } from '@/lib/debug';

import { Token } from '../types';
const debug = debugs.auth.extend('OxidAccessToken');

let oxidAccessTokenFetchPromise: Promise<Token<OxidAccessTokenPayload>> | undefined = undefined;

export async function fetchOxidAccessToken(
	accountId?: string | null,
	cognitoAccessToken?: string | null,
): Promise<Token<OxidAccessTokenPayload>> {
	debug('fetchOxidAccessToken', { accountId, cognitoAccessToken });

	if (!accountId || !cognitoAccessToken) {
		throw new Error('fetchOxidAccessToken: accountId and cognitoAccessToken are both required');
	}

	if (oxidAccessTokenFetchPromise) {
		debug('ongoing refresh. returning promise...');
		return oxidAccessTokenFetchPromise;
	}

	oxidAccessTokenFetchPromise = new Promise(async (resolve, reject) => {
		try {
			debug('fetching new token...');
			const response = await fetch(`${process.env.NEXT_PUBLIC_OXID_API_ORIGIN}/auth/account`, {
				method: 'POST',
				// credentials: 'include',
				headers: {
					Authorization: cognitoAccessToken ?? '',
				},
				body: JSON.stringify({ accountId }),
			});

			if (response.status !== 200) {
				debug('failed to fetch token...');
				throw new Error(`Failed to fetch token. Status: ${response.status}`);
			}

			const { access_token } = (await response.json()) as { access_token: string };

			debug('setting token cookie...');

			const payload = jose.decodeJwt(access_token) as OxidAccessTokenPayload;

			resolve({
				payload,
				stringValue: access_token,
			});
			debug('token fetch complete...');
		} catch (error: any) {
			Sentry.captureException(new Error(`Could not refresh oxid access token`, { cause: error }), {
				extra: { accountId, cognitoAccessToken },
			});

			reject(error);
		}
	});

	return oxidAccessTokenFetchPromise;
}
