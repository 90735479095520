import { atom } from 'jotai';
import { intersection } from 'lodash-es';

export const atomWithLocalStorage = <T>(key: string, initialValue: T) => {
	const getInitialValue = () => {
		if (typeof window !== 'undefined') {
			const item = localStorage.getItem(key);

			if (item !== null) {
				return JSON.parse(item) as T;
			}
		}

		return initialValue;
	};

	const baseAtom = atom(getInitialValue());
	baseAtom.debugPrivate = true;

	const derivedAtom = atom<T, [T], void>(
		(get) => get(baseAtom),
		(get, set, update) => {
			const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
			set(baseAtom, nextValue as T);

			if (nextValue === undefined) {
				localStorage.removeItem(key);
				return;
			}

			localStorage.setItem(key, JSON.stringify(nextValue));
		},
	);

	return derivedAtom;
};

export const uniqueListAtom = <T extends unknown[]>(initialValue: T) => {
	const baseAtom = atom(initialValue);

	const derivedAtom = atom<T, [T], T>(
		(get) => get(baseAtom),
		(get, set, update) => {
			const current = get(baseAtom);

			// skip update if it is already included
			if (intersection(current, update).length === update.length) return current;

			const next = Array.from(new Set([...current, ...update])) as T;
			set(baseAtom, next);
			return next;
		},
	);

	return derivedAtom;
};
