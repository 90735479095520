import { fromNodeId } from '@packages/appsync/utils/id';
import { OxidAccessTokenPayload } from '@packages/static/auth';

import { debugs } from '@/lib/debug';

import type { UserSessionAccount, Token, CurrentUserSessionAccount } from './types';
import { LogoutReason } from './user-session-state-machine';
import '@packages/appsync/utils/polyfill';

const d = debugs.auth.extend('user-session-data');

const keys = {
	oxidAccessToken: 'oxidAccessToken',
	currentAccount: 'currentAccount',
	requestedUrl: 'requestedUrl',
};

export const userSessionData = {
	ablySessionId: {
		get() {
			if (typeof window == 'undefined') return null;

			const value = window.sessionStorage.getItem('ablySessionId');
			if (!value) return null;

			return value;
		},
		set(value: string) {
			if (typeof window !== 'undefined') {
				window.sessionStorage.setItem('ablySessionId', value);
			}
		},
		remove() {
			if (typeof window !== 'undefined') {
				window.sessionStorage.removeItem('ablySessionId');
			}
		},
	},
	logoutReason: {
		get() {
			if (typeof window == 'undefined') return null;

			const value = window.sessionStorage.getItem('logoutReason');
			if (!value) return null;

			return value as LogoutReason;
		},
		set(value: LogoutReason) {
			if (typeof window !== 'undefined') {
				window.sessionStorage.setItem('logoutReason', value);
			}
		},
		remove() {
			if (typeof window !== 'undefined') {
				window.sessionStorage.removeItem('logoutReason');
			}
		},
	},
	requestedUrl: {
		get() {
			if (typeof window == 'undefined') return null;

			const value = window.sessionStorage.getItem(keys.requestedUrl);
			if (!value) return null;

			return new URL(value);
		},
		set(url: URL | null) {
			if (typeof window !== 'undefined') {
				if (!url) {
					window.sessionStorage.removeItem(keys.requestedUrl);
					return;
				} else {
					window.sessionStorage.setItem(keys.requestedUrl, url.href);
				}
			}
		},
		remove() {
			if (typeof window !== 'undefined') {
				window.sessionStorage.removeItem(keys.requestedUrl);
			}
		},
	},
	oxidAccessToken: {
		onChange(callback: (newValue: string | null, oldValue: string | null) => void) {
			if (typeof window === 'undefined') return;

			function handler(event: StorageEvent) {
				if (event.key === keys.oxidAccessToken) {
					d('WARNING: Oxid access token was changed by another app instance.', event);
					callback(event.newValue, event.oldValue);
				}
			}

			window.addEventListener('storage', handler);
			return () => window.removeEventListener('storage', handler);
		},
		get(accountId?: string | null) {
			if (typeof window === 'undefined') return null;

			if (!accountId) {
				d('WARNING: No accountId provided to loadOxidAccessToken.');
				return null;
			}

			const { pk, sk } = fromNodeId(accountId);

			const jsonString = window.localStorage.getItem(keys.oxidAccessToken);

			if (!jsonString) return null;

			const { payload, stringValue } = JSON.parse(jsonString) as Token<OxidAccessTokenPayload>;

			if (payload.exp * 1000 < Date.now() - 1000 * 60 * 5 || payload.account_sk !== sk || payload.tenant_pk !== pk) {
				return null;
			}

			return { stringValue, payload };
		},
		set(token: Token<OxidAccessTokenPayload>) {
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(keys.oxidAccessToken, JSON.stringify(token));
			}
		},
		remove() {
			if (typeof window !== 'undefined') {
				window.localStorage.removeItem(keys.oxidAccessToken);
			}
		},
	},
	currentAccount: {
		onChange(callback: (value: string | null) => void) {
			if (typeof window === 'undefined') return;

			function handler(event: StorageEvent) {
				if (event.key === keys.currentAccount) {
					console.warn('currentAccountId.onChange', event.newValue);
					callback(event.newValue);
				}
			}

			window.addEventListener('storage', handler);
			return () => window.removeEventListener('storage', handler);
		},
		get(userAccounts: UserSessionAccount[]) {
			if (typeof window === 'undefined') return null;
			const value = window.localStorage.getItem(keys.currentAccount);

			if (!value) return null;

			const currentAccount = JSON.parse(value) as CurrentUserSessionAccount;

			/**
			 * if the stored account id does not belong to the current user, remove it and return null
			 */
			if (!userAccounts.some((account) => account.id === currentAccount.id)) {
				localStorage.removeItem(keys.currentAccount);
				return null;
			}

			return currentAccount;
		},
		set(currentAccount: CurrentUserSessionAccount) {
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(keys.currentAccount, JSON.stringify(currentAccount));
			}
		},
		remove() {
			if (typeof window !== 'undefined') {
				window.localStorage.removeItem(keys.currentAccount);
			}
		},
	},
};
