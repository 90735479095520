import gql from 'graphql-tag';

import { apolloClient } from '@/providers/GraphQLProvider';

import type { UserWithAccountsQuery } from './fetchUserWithAccounts.gql.generated';

export const fetchUserWithAccounts = async (cognitoAccessTokenValue?: string | null) => {
	if (!cognitoAccessTokenValue) {
		throw new Error('No cognitoAccessTokenValue provided to fetchUseraccounts');
	}

	const result = await apolloClient.query<UserWithAccountsQuery>({
		context: {
			headers: {
				Authorization: `Bearer ${cognitoAccessTokenValue}`,
			},
		},
		query: gql`
			query UserWithAccounts {
				user {
					firstname
					lastname

					accounts {
						id
						role
						tenant {
							id
							name
							__typename
						}
						__typename
					}
				}
			}
		`,
	});

	return result.data;
};
