// AppSync recommends using UUIDs for Subscription IDs but SubscriptionClient uses an incrementing number
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 as uuid4 } from 'uuid';
// @ts-expect-error - generateOperationId is not public
export class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
    generateOperationId() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return uuid4();
    }
}
