/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// import type * as __ from '../../../node_modules/.pnpm/@types+ws@8.5.10/node_modules/@types/ws';
// import {} from 'ws'
export const createAppSyncAuthorizedWebSocket = (getAppSyncAuthorizationInfo) => {
    return class ExtendedWebSocket extends WebSocket {
        // SubscriptionClient takes a fixed websocket url so we append query string parameters every time the websocket
        // is created, in case the authorization information has changed.
        constructor(url, protocols = undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            super(`${url}?header=${btoa(JSON.stringify(getAppSyncAuthorizationInfo()))}&payload=${btoa(JSON.stringify({}))}`, protocols);
        }
        // AppSync acknowledges GraphQL subscriptions with "start_ack" messages but SubscriptionClient cannot handle them
        // @ts-expect-error - this is ook
        set onmessage(handler) {
            // @ts-expect-error - this is ook
            super.onmessage = (event) => {
                if (event.data) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    const data = this._tryParseJsonString(event.data);
                    if (data && data.type === 'start_ack') {
                        return;
                    }
                }
                return handler(event);
            };
        }
        _tryParseJsonString(jsonString) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return JSON.parse(jsonString);
            }
            catch (e) {
                return undefined;
            }
        }
    };
};
