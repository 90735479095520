let _token = null;
export function prewarmToken(token) {
    _token = token;
}
export const cacheWithAsyncRefresh = ({ appSyncApiUrl, getJwtToken }) => {
    let value = {
        host: new URL(appSyncApiUrl).host,
        Authorization: _token ?? '',
    };
    const asyncRefresh = async () => {
        value = {
            host: new URL(appSyncApiUrl).host,
            Authorization: await getJwtToken(),
        };
    };
    // Warm cache
    void asyncRefresh();
    return () => {
        void asyncRefresh();
        return value;
    };
};
