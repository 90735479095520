/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { fromNodeId } from '@packages/appsync/utils/id';
import { OxidAccessTokenPayload } from '@packages/static/auth';
// import { inspect } from '@xstate/inspect';
import { Amplify } from 'aws-amplify';
import { redirect, RedirectType } from 'next/navigation';
import { createMachine, assign, StateFrom, interpret } from 'xstate';

import { routes } from '@/config';
import { debugs } from '@/lib/debug';
import { cognitoAccessTokenAtom, currentAccountAtom, oxidAccessTokenAtom } from '@/state/state';
import { globalStore } from '@/state/store';

import amplifyConfiguration from './amplifyconfiguration';
import { clearUserSession } from './async-actions/clearUserSession';
import { fetchAuthenticationData } from './async-actions/fetchAuthenticationData';
import { fetchOxidAccessToken } from './async-actions/fetchOxidAccessToken';
import { fetchUserWithAccounts } from './async-actions/fetchUserWithAccounts';
import { Token, TUserSessionContext, UserSessionAccount } from './types';
import { userSessionData } from './user-session-data';
import '@packages/appsync/utils/polyfill';

// if (typeof window !== 'undefined') {
// 	inspect({
// 		iframe: false,
// 	});
// }

const d = debugs.auth.extend('user-session-state-machine');

/** ==============================================================================================
 *
 * TODO: when we have authorized, we store the oxid access token in localstorage > but that won't help
 * when we are on different domains... and we cannot 'listen' to cookie changes. so we need another solution
 * or get rid of the cookie usage!
 *
 * _______________________________________________________________________________________________ */

export const initialUserSessionContext: TUserSessionContext = {
	user: null,
	accounts: null,
	currentAccount: null,
	tokens: {
		cognitoAccessToken: null,
		cognitoIdToken: null,
		oxidAccessToken: null,
	},
	initialUrl: typeof window === 'undefined' ? null : new URL(window.location.href),
	error: null,
};

export enum LogoutReason {
	MULTIPLE_ACCOUNT_INSTANCES = 'MULTIPLE_ACCOUNT_INSTANCES',
}

export const userSessionMachine = createMachine(
	{
		id: 'userSession',
		predictableActionArguments: true,
		schema: {
			context: {} as TUserSessionContext,
			events: {} as
				| { type: 'AUTHENTICATED' }
				| { type: 'UNAUTHENTICATED' }
				| { type: 'AUTHENTICATION_REFRESH_SUCCESS' }
				| { type: 'LOGOUT'; data?: { reason: LogoutReason } }
				| { type: 'SELECT_ACOUNT' }
				| { type: 'ACCOUNT_SELECTED'; data: { accountId: string } }
				| { type: 'REQUEST_CREATE_ACCOUNT' }
				| { type: 'ACCOUNT_CREATED' }
				| {
						type: 'ACCOUNT_HYDRATION_COMPLETE';
						data: { accountId: string; oxidAccessToken: Token<OxidAccessTokenPayload> };
				  }
				| { type: 'CANCEL' }
				| { type: 'done.invoke.fetchAuthenticationData'; data: Awaited<ReturnType<typeof fetchAuthenticationData>> }
				| { type: 'done.invoke.fetchUserWithAccounts'; data: Awaited<ReturnType<typeof fetchUserWithAccounts>> }
				| { type: 'done.invoke.fetchOxidAccessToken'; data: Awaited<ReturnType<typeof fetchOxidAccessToken>> },
		},
		tsTypes: {} as import('./user-session-state-machine.typegen').Typegen0,
		initial: 'authentication',
		context: initialUserSessionContext,
		on: {
			LOGOUT: 'logout',
		},
		states: {
			logout: {
				invoke: {
					id: 'clearUserSession',
					src: async (_context, event) => {
						console.log('clear user session start');
						await clearUserSession(event);
						console.log('clear user session end');
					},
					onDone: {
						target: 'authentication.unauthenticated',
					},
				},
			},
			authentication: {
				initial: 'init',
				states: {
					init: {
						entry: ['persistUrl', 'setup'],
						on: {
							AUTHENTICATED: 'authenticated',
							UNAUTHENTICATED: 'unauthenticated',
						},
					},
					unauthenticated: {
						entry: ['redirectToLogin'],
						on: {
							AUTHENTICATED: 'authenticated',
						},
					},
					authenticated: {
						entry: ['redirectToRequestedUrlOrApp'],
						invoke: {
							id: 'fetchAuthenticationData',
							src: fetchAuthenticationData,
							onDone: {
								actions: ['setAuthenticationContext'],
								target: '#userSession.authorization.init',
							},
							onError: {
								target: 'error',
							},
						},
					},
					error: {
						invoke: {
							id: 'handleAuthenticationError',
							// eslint-disable-next-line @typescript-eslint/require-await
							src: async (err) => {
								console.error('🔴 Could not authenticate user', err);
							},
						},
					},
					refresh: {
						// TODO: update the authenticationData
						// Implementation of token refresh logic
					},
				},
			},
			authorization: {
				initial: 'init',
				states: {
					init: {
						invoke: {
							id: 'fetchUserWithAccounts',
							src: async (context) => {
								return fetchUserWithAccounts(context.tokens.cognitoAccessToken?.stringValue);
							},
							onDone: {
								actions: 'setUserAccounts',
								target: 'account_validation',
							},
							onError: {
								actions: [],
								target: 'error',
							},
						},
					},
					account_validation: {
						always: [
							{ target: 'account_hydration', cond: 'canHydrateAccount' },
							{ target: 'no_accounts', cond: 'hasNoAccount' },
							{ target: 'account_selection', cond: 'hasMultipleAccounts' },
							{ target: 'account_selection_complete', cond: 'hasExactlyOneAccount' },
						],
					},
					account_hydration: {
						description: 'hydrate the currentAccount and oxidAccessToken from storage',
						invoke: {
							id: 'hydrateAccount',
							src: (context) => (callback) => {
								const account = userSessionData.currentAccount.get(context.accounts ?? []);
								const oxidAccessToken = userSessionData.oxidAccessToken.get(account?.id);

								if (!account || !oxidAccessToken) {
									console.warn('Could not hydrate account');
									throw new Error('Could not hydrate account');
								}

								callback({
									type: 'ACCOUNT_HYDRATION_COMPLETE',
									data: {
										accountId: account.id,
										oxidAccessToken,
									},
								});
							},
						},
						on: {
							ACCOUNT_HYDRATION_COMPLETE: {
								actions: [
									'setOxidAccessToken',
									'setCurrentAccount',
									assign({
										tokens: (context, event) => ({
											...context.tokens,
											oxidAccessToken: event.data.oxidAccessToken,
										}),
									}),
								],
								target: 'authorized',
							},
						},
					},
					no_accounts: {
						on: { REQUEST_CREATE_ACCOUNT: 'account_creation' },
					},
					account_selection: {
						on: {
							ACCOUNT_SELECTED: 'account_selection_complete',
							REQUEST_CREATE_ACCOUNT: 'account_creation',
							CANCEL: 'authorized',
						},
					},
					account_selection_complete: {
						entry: ['setCurrentAccount'],
						always: ['request_access_token'],
					},
					account_creation: {
						on: {
							CANCEL: 'account_selection',
							ACCOUNT_CREATED: 'account_selection',
						},
						description: 'Create a new account and immediately select the newly created account',
					},
					account_creation_complete: {
						//
					},
					request_access_token: {
						invoke: {
							id: 'fetchOxidAccessToken',
							src: async (context) =>
								fetchOxidAccessToken(context.currentAccount?.id, context.tokens.cognitoAccessToken?.stringValue),
							onDone: {
								actions: ['setOxidAccessToken'],
								target: 'authorized',
							},
							onError: {
								actions: [(context, event, meta) => console.warn('error requesting oxid access token', event.data)],
								target: 'error',
							},
						},
					},
					authorized: {
						initial: 'idle',
						on: {
							SELECT_ACOUNT: 'account_selection',
						},
						entry: ['redirectToRequestedUrlOrApp'],
						// User is authorized
						after: { AUTHORIZATION_REFRESH_TIMEOUT: { target: '.refresh_token' } },
						states: {
							idle: {
								on: {
									AUTHENTICATION_REFRESH_SUCCESS: 'refresh_token',
								},
							},
							refresh_token: {
								invoke: {
									id: 'refreshOxidAccessToken',
									src: async (context) =>
										fetchOxidAccessToken(context.currentAccount?.id, context.tokens.cognitoAccessToken?.stringValue),
									onDone: {
										actions: ['setOxidAccessToken'],
										target: '#userSession.authorization.authorized',
									},
									onError: {
										actions: [() => console.warn('error refreshing oxid access token')],
										target: '#userSession.authorization.error',
									},
								},
							},
						},
					},
					error: {
						invoke: {
							id: 'handleAuthorizationError',
							// eslint-disable-next-line @typescript-eslint/require-await
							src: async (context, event) => console.error('🔴 Authorization error', { context, event }),
						},
					},
				},
			},
		},
	},
	{
		guards: {
			canHydrateAccount: (context) => {
				let result = false;
				try {
					const currentAccount = userSessionData.currentAccount.get(context.accounts ?? []);
					const token = userSessionData.oxidAccessToken.get(currentAccount?.id);

					if (currentAccount && token) {
						result = true;
					}
				} catch (error) {
					return false;
				}

				d('canHydrateAccount', result);

				return result;
			},
			hasNoAccount: (context) => {
				const result = (context.accounts ?? []).length === 0;
				d('hasNoAccount', result);
				return result;
			},
			hasMultipleAccounts: (context) => {
				const result = (context.accounts ?? []).length > 1;
				d('hasMultipleAccounts', result);
				return result;
			},
			hasExactlyOneAccount: (context) => {
				const result = (context.accounts ?? []).length === 1;
				d('hasExactlyOneAccount', result);
				return result;
			},
		},
		actions: {
			setCurrentAccount: assign({
				currentAccount: (context, event) => {
					let currentAccount: UserSessionAccount | null = null;

					if (event.type === 'ACCOUNT_HYDRATION_COMPLETE' || event.type === 'ACCOUNT_SELECTED') {
						currentAccount = context.accounts?.find((account) => account.id === event.data.accountId) ?? null;
					} else {
						currentAccount = context.accounts?.[0] ?? null;
					}

					if (!currentAccount) {
						console.warn('Could not find current account');
						throw new Error('Could not find current account');
					}

					const { pk, sk } = fromNodeId(currentAccount.id);

					const account = {
						...currentAccount,
						pk,
						sk: sk!,
					};

					globalStore.set(currentAccountAtom, account);

					userSessionData.currentAccount.set(account);

					return account;
				},
			}),
			setOxidAccessToken: assign({
				tokens: (context, event) => {
					let token: Token<OxidAccessTokenPayload> | null = null;

					if (event.type === 'ACCOUNT_HYDRATION_COMPLETE') {
						token = event.data.oxidAccessToken;
					} else if (event.type === 'done.invoke.fetchOxidAccessToken') {
						token = event.data;
					}

					userSessionData.oxidAccessToken.set(token!);

					globalStore.set(oxidAccessTokenAtom, token?.stringValue);

					return {
						...context.tokens,
						oxidAccessToken: token,
					};
				},
			}),
			setup: async () => {
				Amplify.configure(amplifyConfiguration);
			},
			persistUrl: (context) => {
				if (context.initialUrl?.pathname.startsWith('/auth')) return;

				// if (userSessionData.requestedUrl.get() === null) {
				userSessionData.requestedUrl.set(context.initialUrl);
				// }
			},

			setAuthenticationContext: assign({
				user: (_, event) => ({ ...event.data.user }),
				tokens: (context, event) => {
					globalStore.set(cognitoAccessTokenAtom, event.data.tokens.cognitoAccessToken.stringValue);

					return {
						...context.tokens,
						cognitoAccessToken: event.data.tokens.cognitoAccessToken,
						cognitoIdToken: event.data.tokens.cognitoIdToken,
					};
				},
			}),
			setUserAccounts: assign({
				accounts: (_, event) => event.data.user?.accounts ?? null,
				user: (context, event) => {
					return {
						...context.user!,
						firstname: event.data.user?.firstname ?? '',
						lastname: event.data.user?.lastname ?? '',
					};
				},
			}),
			redirectToRequestedUrlOrApp: (context, event) => {
				if (typeof window === 'undefined') return;

				let targetUrl = userSessionData.requestedUrl.get();

				if (!targetUrl) {
					targetUrl = new URL(window.location.href);
					targetUrl.pathname = routes.quickship.path;
				}

				if (typeof window !== 'undefined' && document.location.href !== targetUrl.href) {
					userSessionData.requestedUrl.set(null);

					try {
						d('redirect to requested url', targetUrl.href);
						redirect(targetUrl.href, RedirectType.replace);
					} catch {
						if (typeof window !== 'undefined') {
							window.location.href = targetUrl.href;
						}
					}
				}
			},
			redirectToLogin: (context) => {
				if (context.initialUrl && context.initialUrl?.pathname !== routes.auth.login.path) {
					try {
						d('redirect to login', routes.auth.login.path);
						redirect(routes.auth.login.path, RedirectType.replace);
					} catch {
						if (typeof window !== 'undefined') {
							window.location.href = routes.auth.login.path;
						}
					}
				}
			},
		},
		delays: {
			AUTHORIZATION_REFRESH_TIMEOUT: (context) => {
				const payload = context.tokens.oxidAccessToken?.payload;

				if (!payload) {
					throw new Error("Can't calculate refresh timeout. No payload available");
				}

				const expirationDateMsEpoch = payload.exp * 1000;
				const refreshIn = expirationDateMsEpoch - Date.now() - 60 * 5 * 1000;

				return refreshIn;
			},
		},
	},
);

// export const UserSessionContext = createActorContext(userSessionMachine, { devTools: true });

export const userSessionService = interpret(userSessionMachine, { devTools: true }).start();

export type UserSessionMachineState = StateFrom<typeof userSessionMachine>;
