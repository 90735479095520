/**
 * config for our webapp
 */
export const params = {
    environmentCookieName: 'oxid-env',
    app: {
        ottoAppInstallRequestPath: `/app/integrations/otto/install`,
        ottoAppInstallSuccessPath: `/app/settings/markets?status=installation_success&app=otto`,
    },
    local: {
        appOrigin: 'https://ecom-local.oxid.one',
    },
    production: {
        appOrigin: 'https://ecom.oxid.one',
    },
    apiOrigin: {
        // todo setup stages
        production: 'https://api.ec.oxid.one',
        development: 'https://api.ec.oxid.one',
        local: 'https://api.ec.oxid.one',
    },
    /**
     * we use functions to potentially return path parameters and searchParams
     */
    apiEndpoint: {
        authToken: () => `/api/auth/token`,
    },
    /** ----------------------------------------------------------------------------------------------
     * NEW CONFIG FOR DEBUG
     * _______________________________________________________________________________________________ */
    oxidWebApp: {
        ottoAppInstallRequestPath: `/app/integrations/otto/install`,
        ottoAppInstallSuccessPath: `/app/settings/markets?status=installation_success&app=otto`,
        environment: {
            local: {
                appOrigin: 'https://ecom-local.oxid.one',
            },
            production: {
                appOrigin: 'https://ecom.oxid.one',
            },
        },
    },
    dhlReturns: {
        environment: {
            sandbox: {
                username: '2222222222_customer',
                password: 'uBQbZ62!ZiBiVVbhc',
            },
        },
    },
    dhlPaket: {
        enironment: {
            sandbox: {
                origin: `https://api-sandbox.dhl.com`,
            },
            production: {
                origin: `https://api-eu.dhl.com`,
            },
        },
    },
    otto: {
        //
        getRedirectUri: (environment) => `https://api.ec.oxid.one/otto/install/${environment}`,
        scope: 'shipments orders quantities products',
        environment: {
            sandbox: {
                appId: '15f27273-0f40-436e-8b20-9f046c807972',
                tokenUrl: 'https://sandbox.api.otto.market/oauth2/token',
                marketUrl: 'https://sandbox.api.otto.market',
                clientId: 'd9db9e11-d00b-4a0d-af53-d654b6b1828c',
            },
            production: {
                appId: '86d9befa-3e8f-44a8-875e-21b77dbdbf35',
                tokenUrl: 'https://api.otto.market/oauth2/token',
                marketUrl: 'https://api.otto.market',
                clientId: '4e94161d-ef4d-460f-ac79-a5e89befa7b4',
            },
        },
    },
    cookies: {
        proxyToken: 'x-oxid-proxy-token',
        appOrigin: 'x-oxid-app-origin',
    },
    headers: {
        proxyApp: 'x-oxid-app',
        proxyEnvironment: 'x-oxid-proxy-env',
        cache: 'x-oxid-cache',
    },
};
