import { SharedPrinter } from '@packages/static/cloudprint';
import { atom } from 'jotai';

import { atomWithLocalStorage, uniqueListAtom } from './state-utils';

export const isGraphQLSubscriptionEstablishedAtom = atom(false);

export const realtimeParticipantAccountSkListAtom = uniqueListAtom<string[]>([]);

export const cloudprintClientSkListAtom = atom((get) => get(availablePrintersAtom).map((v) => v.accountSk));

export const availablePrintersAtom = atom<SharedPrinter[]>([]);

export const currentPrinterAtom = atomWithLocalStorage<SharedPrinter | null>('currentPrinter', null);

export const isAppPreloadingAtom = atom<boolean | string>(false);

export const appLoadingStateAtom = atom<{
	realtime: boolean;
}>({
	realtime: false,
} as const);
