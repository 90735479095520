import { DeepPartial } from '@apollo/client/utilities';
import { COGNITO_SUPERUSER_GROUP } from '@packages/static/auth';
import * as Sentry from '@sentry/nextjs';
import { fetchAuthSession, type JWT as AuthJWT, fetchUserAttributes } from 'aws-amplify/auth';

import { TUserSessionContext } from '../types';

export const fetchAuthenticationData = async () => {
	const [/* user,  */ attributes, session] = await Promise.all([
		// getCurrentUser(),
		fetchUserAttributes(),
		fetchAuthSession(),
	]).catch((error) => {
		console.info(error);
		Sentry.captureException(error);
		throw error;
	});

	const cognitoIdTokenPayload = session.tokens?.idToken?.payload as AuthJWT['payload'];
	const cognitoIdTokenString = session.tokens?.idToken?.toString() as string;
	const cognitoAccessTokenPayload = session.tokens?.accessToken?.payload as AuthJWT['payload'];
	const cognitoAccessTokenString = session.tokens?.accessToken?.toString() as string;

	return {
		user: {
			email: attributes.email!,
			cognitoId: attributes.sub!,
			oxidUserPk: `u#${attributes.sub!}`,
			isSuperUser:
				((cognitoIdTokenPayload['cognito:groups'] as string[]) ?? [])?.includes(COGNITO_SUPERUSER_GROUP) ?? false,
		},
		tokens: {
			cognitoIdToken: {
				payload: cognitoIdTokenPayload,
				stringValue: cognitoIdTokenString,
			},
			cognitoAccessToken: {
				payload: cognitoAccessTokenPayload,
				stringValue: cognitoAccessTokenString,
			},
		},
	} satisfies DeepPartial<TUserSessionContext>;
};
