import { WebSocketLink } from '@apollo/client/link/ws';
import { createAppSyncAuthorizedWebSocket } from './appSyncAuthorizedWebSocket';
import { createAppSyncGraphQLOperationAdapter } from './appSyncGraphQLOperationAdapter';
import { cacheWithAsyncRefresh } from './asyncUtils';
import { UUIDOperationIdSubscriptionClient } from './UUIDOperationIdSubscriptionClient';
const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000;
export const createAppSyncSubscriptionWebsocketLink = ({ appSyncApiUrl, getJwtToken }, connectionCallback) => {
    const url = new URL(appSyncApiUrl);
    url.protocol = 'wss:';
    url.pathname = url.pathname + '/realtime';
    const cacheResult = cacheWithAsyncRefresh({ appSyncApiUrl, getJwtToken });
    return new WebSocketLink(new UUIDOperationIdSubscriptionClient(url.toString(), {
        connectionCallback,
        timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS,
        reconnect: true,
        lazy: true,
        connectionParams: async () => ({
            host: url.host,
            Authorization: await getJwtToken(),
        }),
    }, 
    // We want to avoid expired authorization information being used but SubscriptionClient synchronously
    // instantiates websockets (on connection/reconnection) so the best we can do is schedule an async refresh
    // and suffer failed connection attempts until a fresh token has been retrieved
    createAppSyncAuthorizedWebSocket(cacheResult)).use([createAppSyncGraphQLOperationAdapter({ appSyncApiUrl, getJwtToken })]));
};
