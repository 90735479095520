export const DEFAULT_ISSUER = 'https://oxid.one';
export const DEFAULT_AUDIENCE = 'https://oxid.one';
export const OXID_TOKEN_CLAIMS = {
    issuer: DEFAULT_ISSUER,
    audience: DEFAULT_AUDIENCE,
};
export const COGNITO_SUPERUSER_GROUP = 'SuperUser';
export const OXID_TOKEN_REQUIRED_CLAIMS = ['sub', 'tenant_pk', 'account_sk', 'role'];
export const OXID_ACCESS_TOKEN_COOKIE_NAME = 'oxid_access_token';
export const DEFAULT_COOKIE_ATTRIBUTES = {
    secure: true,
    domain: 'oxid.one',
    sameSite: 'Lax',
    path: '/',
};
export const AccountRole = {
    OWNER: 'owner',
    ADMIN: 'admin',
    // VIEWER = 'viewer',
    SHIPPER: 'shipper',
    CLOUDPRINT: 'cloudprint',
};
