/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/link-context';
export const createAppSyncHttpLink = function ({ appSyncApiUrl, getJwtToken }) {
    // eslint-disable-next-line @typescript-eslint/require-await
    const authorizationHeaderLink = setContext(async (_request, previousContext) => {
        return {
            ...previousContext,
            headers: {
                ...previousContext.headers,
                Authorization: previousContext.headers?.Authorization ?? (await getJwtToken()),
            },
        };
    });
    return ApolloLink.concat(authorizationHeaderLink, new HttpLink({ uri: appSyncApiUrl }));
};
