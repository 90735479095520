import { IconGauge, IconSettings, IconUserCircle, IconShip, IconListDetails } from '@tabler/icons-react';

export enum SettingsPageTabs {
	General = 'general',
	Users = 'users',
	Printers = 'printers',
	Markets = 'markets',
	Shippers = 'shippers',
	Debug = 'debug',
	ShippingRules = 'shipping-rules',
}

export const routes = {
	archive: {
		path: '/app/archive',
		icon: IconListDetails,
	},
	dashboard: {
		path: '/app/dashboard',
		icon: IconGauge,
	},
	quickship: {
		path: '/app/quickship',
		icon: IconShip,
	},
	settings: {
		path: '/app/settings/',
		icon: IconSettings,
	},
	profile: {
		path: '/app/profile',
		icon: IconUserCircle,
	},
	account: {
		select: {
			path: '/account/select',
		},
		create: {
			path: '/account/create',
		},
	},
	auth: {
		login: {
			path: '/auth/login',
		},
	},
	external: {
		ottoInstall: {
			path: `${process.env.NEXT_PUBLIC_OXID_API_NEXT_ORIGIN}/otto/install`,
		},
	},
} as const;
