import _debug from 'debug';

export const debug = _debug('oxid');

export const debugs = {
	auth: debug.extend('auth'),
	quickship: debug.extend('quickship'),
	realtime: debug.extend('realtime'),
};

if (typeof window !== 'undefined') {
	// window.localStorage.setItem('debug', 'oxid:*,aws-appsync:*');
	window.localStorage.setItem('debug', 'oxid:*,-oxid:packages:*');
}
